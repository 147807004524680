<template>
  <div class="OnlineClassNet">
    <!-- <div class="search-item"></div> -->
	<div class="table-list">
        <a-table :columns="columns" :data-source="dataList" :pagination="false">
            <div slot="EquipmentAndNet" slot-scope="text, record, index">
                <a href="#" style="color: #1890ff;" >查看</a>
            </div>
            <div slot="SeePlayBack" slot-scope="text, record, index">
                <a href="#" v-if="text" @click="SeePlayBack(text)" style="color: #1890ff;">播放</a>
            </div>
            <div slot="StudentNum" slot-scope="text, record, index">
                <a href="#" @click="_StudentList(record)" style="color: #1890ff;">{{text}}</a>
            </div>
            <div slot="AttendanceNum" slot-scope="text, record, index">
                <a href="#" @click="_StudentList(record)" style="color: #1890ff;">{{text}}</a>
            </div>
        </a-table>
        <a-pagination v-model="currPage" v-if="DataCount > 10" style="margin-top: 18px;text-align: right;" @change="_changePage" :total="DataCount"  show-less-items />
    </div>

    <div class="shade" style="opacity: .8" @click="_onCloseVideo" v-if="shade"></div>
    <div v-show="CurrPlayUrl" class="seevideo">
        <p style="color: #fff;text-align: right;cursor: pointer;" @click="_onCloseVideo">关闭</p>
        <video :src="CurrPlayUrl" ref="video" controls="controls" controlsList="nodownload" style="width:700px" id="video1" ></video>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import moment from 'moment'

export default {
	name: 'OnlineClassNet',
    data () {
        return {
            userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
            dataList :[],
            RangesConf:{},
            SelectId:'',
            StartDate:undefined,
            EndDate:undefined,
            defaultDate:[moment().week(moment().week()).startOf('week'),moment()],
            currPage:1,
            DataCount:0,
            LabelName:'ClassSummary|Individual|',
            OrgIds:[],
            SelectDataList:[],
            func:'',
            columns:[
                {
                    title: '时间',
                    dataIndex: 'ReportTime',
                    key: 'ReportTime',
                    width: 200,
                },
                {
                    title: '标识码',
                    dataIndex: 'Mac',
                    key: 'Mac',
                    ellipsis: true,
                },
                {
                    title: '设备类型',
                    dataIndex: 'DeviceType',
                    key: 'DeviceType',
                },
                {
                    title: '操作系统',
                    dataIndex: 'OperatingSystem',
                    key: 'OperatingSystem',
                    ellipsis: true,
                },
                {
                    title: '版本号',
                    dataIndex: 'ClassInVersion',
                    key: 'ClassInVersion',
                    width: 100,
                },
                {
                    title: 'CPU',
                    dataIndex: 'CPU',
                    key: 'CPU',
                    ellipsis: true,
                },
                // {
                //     title: 'cpu使用率',
                //     dataIndex: 'CpuUseRete',
                //     key: 'CpuUseRete',
                // },
                {
                    title: '摄像头',
                    dataIndex: 'EEO_VIDEO_DEVICE_NAME',
                    key: 'EEO_VIDEO_DEVICE_NAME',
                    ellipsis: true,
                },
                {
                    title: '扬声器',
                    dataIndex: 'EEO_AUDIO_OUTPUT_NAME',
                    key: 'EEO_AUDIO_OUTPUT_NAME',
                    ellipsis: true,
                },
                {
                    title: '麦克风',
                    dataIndex: 'EEO_AUDIO_DEVICE_NAME',
                    key: 'EEO_AUDIO_DEVICE_NAME',
                    ellipsis: true,
                },
                // {
                //     title: '网络数据',
                //     dataIndex: 'NetData',
                //     key: 'NetData',
                // },
            ],
            shade:false,
            CurrPlayUrl:'',
            treeData: [],
            SchoolIds:[],
            SHOW_PARENT: 'SHOW_ALL',
        }
    },
    props: ['OnlineClassNetData'],
    watch: {
        OnlineClassNetData: {
            immediate: true,
            handler (val) {
                this._getData(val);
            },
            deep: true
        }
    },
    created () {

        // this.RangesConf = {
        //     '上上月': [
        //         moment().subtract(2,'months').startOf('month'),
        //         moment().subtract(2,'months').endOf('month')
        //     ],
        //     '上月':[
        //         moment().subtract(1,'months').startOf('month'),
        //         moment().subtract(1,'months').endOf('month')
        //     ],
        //     '本月':[
        //         moment().startOf("month"),
        //         moment().endOf('month')
        //     ]
        // }

        // this.StartDate = moment().week(moment().week()).startOf('week').format('YYYY-MM-DD');
        // this.EndDate   = moment().format('YYYY-MM-DD');

        // this._treeData();

        // this._getData();
    },
    mounted(){
        
    },
    methods: {
        moment,
        onDateChange(v){
            if (v.length > 0) {
                this.StartDate = this.__moment__(v[0]).format('YYYY-MM-DD');
                this.EndDate = this.__moment__(v[1]).format('YYYY-MM-DD');
            }
        },
        handleSelectChange(v){
            this.SelectId = v;
        },
        reset(){
            this.SelectId = '';
            this.OrgIds = [];
        },
        _search(){
            this.currPage = 1;
            this._getData();
        },
        _getData(data = {}){
            this.$message.loading('拉取数据中...')
            this.$axios.post(8000101,{
                    LabelName:'OnlineClass|Teacher|net',
                    CourseId:data.CourseId,
                    UID:data.UID,
                    Page:this.currPage
                },
                res => {
                    console.log(res)
                    if (res.data.code == 1) {
                        this.dataList = res.data.data.list;
                        this.DataCount = res.data.data.count;
                    } else {
                        this.dataList = [];
                        this.DataCount = 0;
                    }
                    this.$message.destroy()
                }
            )
        },
        _changePage(p){
            this.currPage = p;
            this. _getData();
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        _onCloseVideo(){
            this.shade = false;
            this.CurrPlayUrl = '';
            this.$refs.video.pause();
        },
        SeePlayBack(url){
            this.shade = true;
            this.CurrPlayUrl = url;
        },
        _treeData () {
          let self = this
          this.$axios.get(
            1090,
            {
              UserId: self.userinfo.uid
            },
            res => {
              self.treeData = res.data
            }
          )
        },
        onOrgChange(value, label, text){
            if (text.checked) {
                this.SchoolIds.push(text.triggerValue)
                } else {
                if (this.SchoolIds.length > 0) {
                  for (var i = 0; i < this.SchoolIds.length; i++) {
                    if (text.triggerValue == this.SchoolIds[i]) {
                      this.SchoolIds.splice(i, 1)
                    }
                  }
                }
            }

            this.OrgName = label
            this.OrgIds = value
            console.log(this.SchoolIds);
            console.log(this.OrgIds);
        },
        _StudentList(d){
            console.log(d);
        }
    }
}
</script>

<style lang="less" scoped>
.OnlineClassNet {
    width: 100%;
    min-height: 100%;
    background-size: 100%;
    position: relative;
}


.search-item{
    margin-left: 19px;
    margin-top: 33px;
    display: inline-flex;
}

.shade{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .5;
    z-index: 99999;
}
.seevideo{
    position: absolute;
    top: 1%;
    left: 49%;
    transform: translate(-50%, 50%);
    z-index: 999999;
}
</style>
