<template>
  <div class="OnlineClassStudentList">

    <div class="stat-data" v-if="summary_data">
        <!-- <div class="stat-item">
            <div class="stat-text">
                <p class="stat-num">{{summary_data.Cost}}</p>
                <p class="stat-text-title">费用总计</p>
            </div>
        </div> -->
        <div class="stat-item">
            <div class="stat-text">
                <p class="stat-num">{{summary_data.attendance_data.attendance}}</p>
                <p class="stat-text-title">出勤人数(迟到 {{summary_data.attendance_data.late}} 早退 {{summary_data.attendance_data.leave_early}})</p>
            </div>
        </div>
        <div class="stat-item">
            <div class="stat-text">
                <p class="stat-num">{{summary_data.absence_count}}</p>
                <p class="stat-text-title">缺勤人数</p>
            </div>
        </div>
        <div class="stat-item">
            <div class="stat-text">
                <p class="stat-num">{{summary_data.ActualClassTime}}</p>
                <p class="stat-text-title">实际上课(小时)</p>
            </div>
        </div>
    </div>

    <div class="search-item">
        <a-tree-select
            style="width: 250px;margin-right: 10px;"
            v-model="OrgIds"
            :tree-data="treeData"
            tree-checkable
            :maxTagCount="1"
            placeholder="请选择搜索范围"
            @change="onOrgChange"
            :show-checked-strategy="SHOW_PARENT"
          />

        <a-input type="text" v-model="Keyword" placeholder="搜索学生名称" style="width: 150px;margin-right: 10px;"/>
        <a-input type="text" v-model="TeacherName" placeholder="搜索上课老师名称" style="width: 150px;margin-right: 10px;"/>

        <!-- <a-select placeholder="是否跟踪" v-model="isRecord"  style="width: 150px;margin-right: 10px;">
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="1">是</a-select-option>
            <a-select-option value="0">否</a-select-option>
        </a-select> -->

        <a-range-picker :ranges="RangesConf" @change="onDateChange" style="margin-right: 10px;" :defaultValue="defaultDate"/>

        <a-button style="margin-right: 10px;" @click="reset">重 置</a-button>

        <a-button type="primary" @click="_search">搜 索</a-button>
    </div>
	<div class="table-list">
        <a-table :columns="columns" :data-source="dataList" :pagination="false">
            <!-- <div slot="EquipmentAndNet" slot-scope="text, record, index">
                <a href="#" style="color: #1890ff;" @click="_showOnlineClassDevice(record)">设备</a>&nbsp;&nbsp;
            </div> -->

            <div v-if="record.ActualClassTime > 0" slot="EquipmentAndNet" slot-scope="text, record, index">
                <a href="#" style="color: #1890ff;" @click="_showOnlineClassDevice(record)">设备</a>&nbsp;&nbsp;
                <a-popover trigger="hover" placement="left" @visibleChange="_showEchart(record,'net',$event)">
                  <template slot="content">
                    <div :id="'echarts-data-net-'+record.ClassinId" style="width:550px;height:350px"></div>
                  </template>
                  <a href="#" style="color: #1890ff;">网络</a>
                </a-popover>
                <!-- &nbsp;&nbsp;
                <a-popover  trigger="hover" placement="left" @visibleChange="_showEchart(record,'cpu',$event)">
                  <template slot="content">
                    <div :id="'echarts-data-cpu-'+index" style="width:550px;height:350px"></div>
                  </template>
                  <a href="#" style="color: #1890ff;">CPU</a>
                </a-popover> -->
            </div>
            <div v-else slot="EquipmentAndNet" slot-scope="text, record, index" style="text-align: left;">
                --
            </div>

            <div slot="SeePlayBack" slot-scope="text, record, index">
                <a href="#" v-if="text" @click="SeePlayBack(text)" style="color: #1890ff;">播放</a>
            </div>
            <div slot="StudentNum" slot-scope="text, record, index">
                <a href="#" @click="_StudentList(record)" style="color: #1890ff;">{{text}}</a>
            </div>
            <div slot="AttendanceNum" slot-scope="text, record, index">
                <a href="#" @click="_StudentList(record)" style="color: #1890ff;">{{text}}</a>
            </div>
            <div slot="ClassInfo" slot-scope="text, record, index" style="font-size:10px">
                <p><b>上课时间:</b>{{record.ClassDate}}</p>
                <p><b>课程名称:</b>{{record.ClasstrackInfo.ClassName}}-{{record.ClasstrackInfo.ClassStage}}</p>
                <p><b>年龄段:</b>{{record.ClasstrackInfo.Age}}-{{record.ClasstrackInfo.Month}}-{{record.ClasstrackInfo.Week}}</p>
            </div>
            <div slot="TeacherName" slot-scope="text, record, index" style="font-size:10px">
                {{record.ClasstrackInfo.TeacherName}}
            </div>
        </a-table>
        <a-pagination v-model="currPage" v-if="DataCount > 10" style="margin-top: 18px;text-align: right;" @change="_changePage" :total="DataCount"  show-less-items />
    </div>

    <a-drawer title="网络信息" placement="right" width="1200" :closable="false" @close="onCloseOnlineClassNet":visible="OnlineClassNetVisible">
        <OnlineClassNet :OnlineClassNetData="OnlineClassNetData"></OnlineClassNet>
    </a-drawer>

    <a-drawer title="设备信息" placement="right" width="1200" :closable="false" @close="onCloseOnlineClassDevice":visible="OnlineClassDeviceVisible">
        <OnlineClassDevice :OnlineClassDeviceData="OnlineClassDeviceData"></OnlineClassDevice>
    </a-drawer>

  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import moment from 'moment'
import OnlineClassNet from 'pages/ReportCenter/OnlineClassNet'
import OnlineClassDevice from 'pages/ReportCenter/OnlineClassDevice'
import * as echarts from 'echarts'
export default {
	name: 'OnlineClassStudentList',
    components: { OnlineClassNet, OnlineClassDevice, echarts },
    data () {
        return {
            userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
            dataList :[],
            RangesConf:{},
            SelectId:'',
            StartDate:undefined,
            EndDate:undefined,
            defaultDate:[moment().week(moment().week()).startOf('week'),moment()],
            currPage:1,
            DataCount:0,
            LabelName:'ClassSummary|Individual|',
            OrgIds:[],
            SelectDataList:[],
            func:'',
            columns:[
                {
                    title: '序号',
                    dataIndex: 'Num',
                    key: 'Num',
                },
                {
                    title: '名称',
                    dataIndex: 'StudentName',
                    key: 'StudentName',
                },
                {
                    title: '手机号',
                    dataIndex: 'Phone',
                    key: 'Phone',
                },
                {
                    title: '所属中心',
                    dataIndex: 'SchoolName',
                    key: 'SchoolName',
                },
                {
                    title: '课程信息',
                    dataIndex: 'ClassInfo',
                    key: 'ClassInfo',
                    scopedSlots: { customRender: 'ClassInfo' }
                },
                {
                    title: '上课老师',
                    dataIndex: 'TeacherName',
                    key: 'TeacherName',
                    scopedSlots: { customRender: 'TeacherName' }
                },
                {
                    title: '出勤',
                    dataIndex: 'Attendance',
                    key: 'Attendance',
                },
                {
                    title: '迟到',
                    dataIndex: 'Late',
                    key: 'Late',
                },
                {
                    title: '早退',
                    dataIndex: 'LeaveEarly',
                    key: 'LeaveEarly',
                },
                {
                    title: '上课时长(分)',
                    dataIndex: 'ActualClassTime',
                    key: 'ActualClassTime',
                },
                {
                    title: '设备及网络信息',
                    dataIndex: 'EquipmentAndNet',
                    key: 'EquipmentAndNet',
                    scopedSlots: { customRender: 'EquipmentAndNet' }
                },
            ],
            shade:false,
            CurrPlayUrl:'',
            treeData: [],
            SchoolIds:[],
            SHOW_PARENT: 'SHOW_ALL',
            OnlineClassNetData:'',
            OnlineClassNetVisible:false,
            OnlineClassDeviceData:'',
            OnlineClassDeviceVisible:false,
            _CurrShowStr:'',
            _NetData:[],
            summary_data:'',
            Keyword:'',
            OrderField:'_id',
            Order:'desc',
            TeacherName:'',
        }
    },
    props: ['queryData'],
    watch: {
        queryData: {
            immediate: true,
            handler (val) {

            },
            deep: true
        }
    },
    created () {

        this.RangesConf = {
            '上上月': [
                moment().subtract(2,'months').startOf('month'),
                moment().subtract(2,'months').endOf('month')
            ],
            '上月':[
                moment().subtract(1,'months').startOf('month'),
                moment().subtract(1,'months').endOf('month')
            ],
            '本月':[
                moment().startOf("month"),
                moment().endOf('month')
            ]
        }

        this.StartDate = moment().week(moment().week()).startOf('week').format('YYYY-MM-DD');
        this.EndDate   = moment().format('YYYY-MM-DD');

        this._treeData();

        this._getData();
    },
    mounted(){
        
    },
    methods: {
        moment,
        onDateChange(v){
            if (v.length > 0) {
                this.StartDate = this.__moment__(v[0]).format('YYYY-MM-DD');
                this.EndDate = this.__moment__(v[1]).format('YYYY-MM-DD');
            }
        },
        handleSelectChange(v){
            this.SelectId = v;
        },
        reset(){
            this.SelectId = '';
            this.Keyword = '';
            this.TeacherName = '';
            this.OrgIds = [];
        },
        _search(){
            this.currPage = 1;
            this._getData();
        },
        _getData(){
            this.$message.loading('拉取数据中...')
            this.$axios.post(8000101,{
                    LabelName:'OnlineClass|Student|index',
                    OrgIds:this.OrgIds,
                    StartDate:this.StartDate,
                    EndDate:this.EndDate,
                    Page:this.currPage,
                    Keyword:this.Keyword,
                    OrderField:this.OrderField,
                    Order:this.Order,
                    isRecord:this.isRecord,
                    TeacherName:this.TeacherName
                },
                res => {
                    console.log(res)
                    if (res.data.code == 1) {
                        this.dataList = res.data.data.list;
                        this.DataCount = res.data.data.count;
                        this.summary_data = res.data.data.summary_data;
                    } else {
                        this.dataList = [];
                        this.DataCount = 0;
                    }
                    this.$message.destroy()
                }
            )
        },
        _changePage(p){
            this.currPage = p;
            this. _getData();
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        _onCloseVideo(){
            this.shade = false;
            this.CurrPlayUrl = '';
            this.$refs.video.pause();
        },
        SeePlayBack(url){
            this.shade = true;
            this.CurrPlayUrl = url;
        },
        _treeData () {
          let self = this
          this.$axios.get(
            1090,
            {
              UserId: self.userinfo.uid
            },
            res => {
              self.treeData = res.data
            }
          )
        },
        onOrgChange(value, label, text){
            if (text.checked) {
                this.SchoolIds.push(text.triggerValue)
                } else {
                if (this.SchoolIds.length > 0) {
                  for (var i = 0; i < this.SchoolIds.length; i++) {
                    if (text.triggerValue == this.SchoolIds[i]) {
                      this.SchoolIds.splice(i, 1)
                    }
                  }
                }
            }

            this.OrgName = label
            this.OrgIds = value
            console.log(this.SchoolIds);
            console.log(this.OrgIds);
        },
        _StudentList(d){
            console.log(d);
        },
        onCloseOnlineClassNet(){
            this.OnlineClassNetVisible = false;
        },
        _showOnlineClassNet(d){
            console.log(d)
            this.OnlineClassNetVisible = true;
            this.OnlineClassNetData = {
                CourseId:d.CourseId,
                UID:d.ClassinId
            };
        },
        onCloseOnlineClassDevice(){
            this.OnlineClassDeviceVisible = false;
        },
        _showOnlineClassDevice(d){
            this.OnlineClassDeviceVisible = true;
            this.OnlineClassDeviceData = {
                CourseId:d.CourseId,
                UID:d.ClassinId
            };
        },
        _showEchart(record,str,e){
            let that = this;

            that.CurrShowStr = str;

            that.Num = record.ClassinId;

            if (e) { that._getNetCpuData(record,e); }
        },
        _init_echart_net(data,index,e){
            let that = this;
            setTimeout(function(){

                let myChart = echarts.init(document.getElementById('echarts-data-net-'+index),null,null);

                if (!e) {myChart.clear();return;}
                console.log(document.getElementById('echarts-data-net-'+index))
                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // Use axis to trigger tooltip
                            type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
                        }
                    },
                    legend: {
                        data: ['很差', '较差', '正常', '较好']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value'
                    },
                    yAxis: {
                        type: 'category',
                        data: data.yAxis
                    },
                    series: [
                        {
                            name: '很差',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.net.poor,
                            itemStyle:{
                                normal:{
                                    color:'#f34336'
                                }
                            }
                        },
                        {
                            name: '较差',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.net.bad,
                            itemStyle:{
                                normal:{
                                    color:'#f3b414'
                                }
                            }
                        },
                        {
                            name: '正常',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.net.normal,
                            itemStyle:{
                                normal:{
                                    color:'#2ba76c'
                                }
                            }
                        },
                        {
                            name: '较好',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.net.good,
                            itemStyle:{
                                normal:{
                                    color:'#30ba78'
                                }
                            }
                        }
                    ]
                };

                option && myChart.setOption(option);
            },500);
        },
        _getNetCpuData(record,e){
            let that = this;
            this.$axios.post(8000101,{
                    LabelName:'OnlineClass|Teacher|net',
                    CourseId:record.CourseId,
                    UID:record.ClassinId
                },
                res => {
                    this._NetData = [];
                    if (res.data.code == 1) {
                        that._NetData[that.Num] = res.data.data.list;
                        that._init_echart_net(res.data.data.list,that.Num,e);
                    } else {
                        console.log('获取不到数据')
                    }
                }
            )            
        },
    }
}
</script>

<style lang="less" scoped>
.OnlineClassStudentList {
    width: 100%;
    min-height: 100%;
    background-size: 100%;
    position: relative;
    border-radius: 5px;
    background-color: #FFF;
    padding: 15px;
}


.search-item{
    margin-left: 19px;
    margin-top: 33px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.table-list{
    margin-top: 30px;
    margin-left: 20px;
}

.shade{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .5;
    z-index: 99999;
}
.seevideo{
    position: absolute;
    top: 1%;
    left: 49%;
    transform: translate(-50%, 50%);
    z-index: 999999;
}


.stat-data{
    width: 80%;
    background-color: #eff6ff;
    margin-left: 20px;
    height:150px;
    padding:30px;
    margin-top: 20px;
}

.stat-item{
    width: 25%;
    display: -ms-flexbox;
    height: 90px;
    background-color: #ffffff;
    margin-right: 20px;
    display: inline-block;
}
.stat-text{
    margin: 25px;
}
.stat-num{
    font-weight: 600;
    font-size:18px;
}
.stat-text-title{
    font-weight: 300;
    font-size: 12px;
}
</style>
